import { Box, Button, Divider, Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Sheet } from '@mui/joy'
import Table from '@mui/joy/Table';
import { Info } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router-dom';




export default function EventTable() {
//UseState's:

    const token = JSON.parse(localStorage.getItem("logintoken"));
    const auth = JSON.parse(localStorage.getItem('Userdetails'))
    const [edashboard,setEdashboard] = React.useState([])
    const [totalevents,setTotalEvents] = React.useState(0)
    const params = useParams()



//Hook's state:
    React.useEffect(()=>{
        EventDashboard()
    },[])
    

    const EventDashboard = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `Event/GetEventsbyCreatorId/${params.id}`)
        .then((res)=>{
            console.log(res.data.event)
            setEdashboard(res.data.event);
            setTotalEvents(res.data.totalcount)
        })
        .catch((error)=>{
            console.log(error,"error in EventDashboard");
        })
    }

    const handleNavigate = (url) => {
        let newurl = window.open(url, "_blank");
        newurl.focus();
      };


    return (
        <Box className="individual_table">
            <Box className="p-10 flex-between flex-center">
                <Box className="flex-center">
                    <Typography component='h6' variant='h6' className='semibold letter_sp fz-16 mr-10'>Events</Typography>
                    <Tooltip title="watch video" className='flex-center text-right'>
                        <Info className='fz-18' />
                    </Tooltip>
                </Box>
                <Typography component='h6' variant='h6' className='text_orange semibold letter_sp fz-16 mr-10' style={{ textDecoration: 'underline',marginLeft:'140px',cursor:'pointer' }} 
                 onClick={() =>
                    handleNavigate(
                      `https://yoga-admin.247sites.in/redirectpage/${auth?.email}/${token}`
                    )
                  }>more</Typography>
                <Typography component='div' variant='body' className='countBox letter_sp fz-24'>{totalevents}</Typography>
            </Box>
            <Divider />
            <Box className="p-10">
                {/* <Box className="d-flex justify-content-right">
                    <Tooltip title="watch video" className='flex-center text-right'>
                        <Button className='theme_btn btn-xs'>Add or Edit Event</Button>
                    </Tooltip>
                </Box> */}
                <Box className="table_content ">
                    <Sheet sx={{ height: 200, overflow: 'auto' }}>
                        <Table
                            aria-label="table with sticky header"
                            stickyHeader
                            stickyFooter
                            stripe="odd"
                            hoverRow
                        >
                            <tbody>
                                {edashboard && edashboard?.length > 0 ?
                                (edashboard && edashboard?.map((item,i) => (
                                    <tr key={i}>
                                        <td>
                                            <Box className="semibold fz-12 mb-10 letter_sp">{item.event_name}</Box>
                                            <Box className="ellipse2 fz-12 text_blue letter_sp">{item.shortDescription ? item.shortDescription : '-'}</Box>
                                        </td>
                                        <td width={100} className='semibold fz-12 letter_sp'>{moment(item.createdAt).format('MMM Do YY')}</td>
                                    </tr>
                                ))) : (
                                    <p>No data found</p>
                                )}
                            </tbody>
                        </Table>
                    </Sheet>
                </Box>
            </Box>
        </Box>
    )
}
