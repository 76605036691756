import { Box, Button, CircularProgress, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import contactusLogo from './../../assets/images/contactusLogo.png';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { getToken } from '@firebase/messaging';
import { messaging } from '../../firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import useCountdown from './useCountdown';





const Auth2Factor = () => {
    let navigate = useNavigate();
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar()
    const [fcmkey, setFcmKey] = useState('')
    const [userdata, setUserData] = React.useState({ email: "",password:"" ,OTP: "" })
    const [error, setError] = useState({ email: "",password:"", OTP: "" })
    const [loading, setLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const {secondsLeft,start}= useCountdown()

    console.log(location.state?.body)
    

    React.useEffect(() => {
        if (location.state?.body) {
        setUserData(prevState => ({
        ...prevState,
        email: location.state.body.email,
        password: location.state.body.password
        }));
        }
        }, [location.state?.body]);

useEffect(() => {
    const fetchData = async () => {
        try {
            const currentToken = await getToken(messaging, { vapidKey: "BCch_dBcI75By1vAAUqNoKNe5elsltpcncqVeMSTPvoZTQtgT149-zC1Pf9l52One6Bh9cvZR1rZoqOeoVR9rKo" });
            console.log(currentToken,'currentToken')
            if (currentToken) {
                setFcmKey(currentToken);
            } else {
                console.log('No FCM token available.');
            }
           
        } catch (error) {
            console.error('Error getting FCM token:', error);
        }
    };

    fetchData(); // Call the async function

    // Note: Dependency array is empty, meaning this effect runs only once after mount

}, []);
let hostname= window.location.hostname;
    console.log(hostname,"hostnamehostnamehostname");
    console.log(fcmkey, "fcmkey");

 // Start countdown on component mount (initial load)
 useEffect(() => {
    start(30);  // Start countdown for 30 seconds on page load
}, []);



const handleChange = (e) => {
    
    setUserData({ ...userdata, [e.target.name]: e.target.value })
    setError({ ...error, [e.target.name]: "" })
}


const handleResendOTP = async (e) => {
    start(30)
    e.preventDefault();
    if (isButtonDisabled) return;
    setIsButtonDisabled(true);
    setLoading(true);
    
        
    
        
const body = {
    email: userdata.email,
    password: userdata.password,
    FCM_token: fcmkey,
    hostname:hostname
};    
            try {
                const res = await axios.post(process.env.REACT_APP_BASE_URL + "Admin/TeacherLogin", body);
        
             
                if (!res.data.teacher.SetVerifyTeacher) {
                    enqueueSnackbar("Your account has not been approved by the admin.", { variant: 'error' });
                    return;
                }
        
                if (res.data.teacher.isTwoFactorAuthentocated === true) {
                    enqueueSnackbar("OTP has been sent to your Mail address", { variant: 'success' });
                    navigate("/login",{ state: { body: body } });
                    setLoading(false)

                }
                else{
                    
                localStorage.setItem("userData", JSON.stringify(res.data.teacher));
                localStorage.setItem("token", res.data.token);
                console.log(res.data, 'Teacher login details');
                enqueueSnackbar("Teacher Login Successfully", { variant: 'success' });
        
                // Construct the dynamic subdomain URL
                const username = res.data.teacher.username; // Adjust if the username field is different
                const subdomainUrl = `https://${username}.247sites.in/dashboard/${res.data.teacher._id}`;
        
                // Redirect to the constructed subdomain URL
                window.location.href = subdomainUrl;
                console.log(window.location.href,"window.location.href");
        
                // navigate(`/teachers/details/events/${res.data.teacher._id}`);
                }
        
                
        
            } catch (err) {
                console.log(err)
                // setError({ email: "", password: "" });
            }finally{
                setLoading(false)
                setTimeout(() => {
                    setIsButtonDisabled(false); // Re-enable the button after 30 seconds
                }, 30000);
            }
        
}

const handleLogin = (e) => {
    e.preventDefault();
   
    
    const body = {
        email: userdata.email,
        otp: userdata.OTP,
        FCM_token: fcmkey,
        hostname:hostname
    };

    console.log(body, "bodddd");
    
    
        axios.post(process.env.REACT_APP_BASE_URL + "Admin/VerifyTeacherOTP", body)
            .then((res) => {
                if (!res.data.teacher.SetVerifyTeacher) {
                    enqueueSnackbar("Your account has not been approved by the admin.", { variant: 'error' });
                    return;
                }
                console.log(res.data.teacher)
                
                localStorage.setItem("userData", JSON.stringify(res.data.teacher));
                localStorage.setItem("token", res.data.token);
                console.log(res.data, 'Teacher login details');
                enqueueSnackbar("Teacher Login Successfully", { variant: 'success' });

                // Construct the dynamic subdomain URL
                const username = res.data.teacher.username; // Adjust if the username field is different
                const subdomainUrl = `https://${username}.247sites.in/dashboard/${res.data.teacher._id}`;

                // Redirect to the constructed subdomain URL
                window.location.href = subdomainUrl;
                // console.log(window.location.href,"window.location.href")


                // navigate(`/teachers/details/events/${res.data.teacher._id}`);
            })
            .catch((err) => {
                
                setError({ email: "", password: "" });
                

                if (err.response) {
                    if(err.response.data.message.includes("OTP")){
                        setError({ ...error, password: err.response.data.error });
                    }
                    // if (err.response.data.error.includes("Email")) {
                    //     setError({ ...error, email: err.response.data.error });
                    // } 
                    // else if (err.response.data.error.includes("Password")) {
                    //     setError({ ...error, password: err.response.data.error });
                    // }
                    // else if (err.response.data.error.includes("OTP")) {
                    //     setError({ ...error, password: err.response.data.error });
                    // } 
                    // else {
                    //     setError({ ...error, password: err.response.data.error });
                    // }
                    enqueueSnackbar(err.response.data.message, { variant: 'error' });
                } else {
                    setError({ ...error, password: "Something went wrong" });
                    enqueueSnackbar("Something went wrong", { variant: 'error' });
                }
            });
    
};

const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
        handleLogin(e)
    }
}


  return (
    <>
    <Box className='login'>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='login_img'>
                        <Box className='logintext_box'>
                            <h1 className='heading semibold'>Yoga</h1>
                            <Typography component='p' variant='body1' className='subheading'>The rhythm of the body, the melody of the mind, and the harmony of the soul create the symphony of life.</Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='Login_content'>
                        <Box className="header_main_left">
                            <img src={contactusLogo} alt="pic" height={100} />
                        </Box>
                        <Typography component='h4' variant='h4'>2 Factor Authentication</Typography>
                        <Box className='form'>
                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Enter The OTP</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    placeholder='OTP'
                                    type={"text"}
                                    className='textfield'
                                    name='OTP'

                                    onKeyDown={_handleKeyDown}
                                    onChange={handleChange} 
                                />
                                {error.password && <p style={{ color: 'red' }} className="fz-13 m-0">{error.password}</p>}
                            </FormControl>
                        </Box>
                        {
                            isButtonDisabled ? 
                            <Button className='loginbtn' disabled={secondsLeft > 0} >
                                {secondsLeft > 0 ? `Please wait ${secondsLeft} seconds` : 'Click to Resend OTP'}</Button>:

                            <Button className='loginbtn' onClick={handleResendOTP}>Resend</Button>
                        }
                     
                        <Button className='loginbtn' onClick={handleLogin}>Login</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </>
  )
}

export default Auth2Factor