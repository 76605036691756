import { Box, Button, Divider, Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Sheet } from '@mui/joy'
import Table from '@mui/joy/Table';
import { Info } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router-dom';


export default function FansActivity() {
//UseState's:
    const [fansdashboard,setFansdashboard] = React.useState([])
    const [totalfans,setTotalFans] = React.useState(0)
    const params  = useParams()



//Hook's state:
    React.useEffect(()=>{
        FanspageDash()
    },[])



    const FanspageDash = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `FanPage/GetFansbyTeacherId/${params.id}?size=${10}&page=${10}&search=${""}&category_id=${"all"}`)
        .then((res)=>{
            setFansdashboard(res.data.getFansPage);
            setTotalFans(res.data.totalcount)
        })
        .catch((error)=>{
            console.log(error,"error in FanspageDash");
        })
    }


    console.log(fansdashboard,"FANS");


    return (
        <Box className="individual_table">
            <Box className="p-10 flex-between flex-center">
                <Box className="flex-center">
                    <Typography component='h6' variant='h6' className='semibold letter_sp fz-16 mr-10'>Fans Activities</Typography>
                    <Tooltip title="watch video" className='flex-center text-right'>
                        <Info className='fz-18' />
                    </Tooltip>
                </Box>
                <Typography component='div' variant='body' className='countBox fz-24'>{totalfans}</Typography>
            </Box>
            <Divider />
            <Box className="p-10">
                {/* <Box className="d-flex justify-content-right">
                    <Tooltip title="watch video" className='flex-center text-right'>
                        <Button className='theme_btn btn-xs'>Add or Edit Activity</Button>
                    </Tooltip>
                </Box> */}
                <Box className="table_content">
                    <Sheet sx={{ height: 200, overflow: 'auto' }}>
                        <Table
                            aria-label="table with sticky header"
                            stickyHeader
                            stickyFooter
                            stripe="odd"
                            hoverRow
                        >
                            <thead>
                                <tr>
                                    <th className='minw100'>Fan Page</th>
                                    <th className='minw100 text-right'>No. of Members</th>
                                    <th>Recent Activity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fansdashboard && fansdashboard?.length > 0 ?
                                (fansdashboard && fansdashboard.map((item,i) => (
                                    <tr key={i}>
                                        <td className='semibold fz-12' >{item.name}</td>
                                        <td className='semibold fz-12 text-right' >{item.member && item.member?.length}</td>
                                        <td ><div className='ellipse2 fz-12 text_blue letter_sp'>{item.short_description}</div></td>
                                    </tr>
                                ))) : (
                                    <p>No data found</p>
                                )}
                            </tbody>
                        </Table>
                    </Sheet>
                </Box>
            </Box>
        </Box>
    )
}
