import { Box, Button, Divider, Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Sheet } from '@mui/joy'
import Table from '@mui/joy/Table';
import { Info } from '@mui/icons-material';

function createData(from, subject, desc, recv_on) {
    return { from, subject, desc, recv_on };
}
const rows = [
    createData('Lorem Ipsum', 'Lorem ipsum dolor', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id libero euismod, fringilla urna eget, scelerisque massa. Fusce ut enim mi. ', '12-02-2024'),
    createData('Lorem Ipsum', 'Lorem ipsum dolor', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id libero euismod, fringilla urna eget, scelerisque massa. Fusce ut enim mi. ', '12-02-2024'),
    createData('Lorem Ipsum', 'Lorem ipsum dolor', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id libero euismod, fringilla urna eget, scelerisque massa. Fusce ut enim mi. ', '12-02-2024'),
    createData('Lorem Ipsum', 'Lorem ipsum dolor', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id libero euismod, fringilla urna eget, scelerisque massa. Fusce ut enim mi. ', '12-02-2024'),
    createData('Lorem Ipsum', 'Lorem ipsum dolor', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id libero euismod, fringilla urna eget, scelerisque massa. Fusce ut enim mi. ', '12-02-2024'),

];


export default function MyMails() {
    return (
        <Box className="individual_table">
            <Box className="p-10 flex-between flex-center">
                <Box className="flex-center">
                    <Typography component='h6' variant='h6' className='semibold letter_sp fz-16 mr-10'>My Mails</Typography>
                    <Tooltip title="watch video" className='flex-center text-right'>
                        <Info className='fz-18' />
                    </Tooltip>
                </Box>
                <Typography component='div' variant='body' className='countBox fz-24'>9</Typography>
            </Box>
            <Divider />
            <Box className="p-10">
                {/* <Box className="d-flex justify-content-right">
                    <Tooltip title="watch video" className='flex-center text-right'>
                        <Button className='theme_btn btn-xs'>View all</Button>
                    </Tooltip>
                </Box> */}
                <Box className="table_content">
                    <Sheet sx={{ height: 200, overflow: 'auto' }}>
                        <Table
                            aria-label="table with sticky header"
                            stickyHeader
                            stickyFooter
                            stripe="odd"
                            hoverRow
                        >
                            <thead>
                                <tr>
                                    <th className='minw100'>Mail From</th>
                                    <th className='minw100 '>Subject</th>
                                    <th>Subject</th>
                                    <th>Received on</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((row) => (
                                    <tr key={row.from}>
                                        <td className='semibold fz-12' >{row.from}</td>
                                        <td className='semibold fz-12 ' >{row.subject}</td>
                                        <td ><div className='ellipse2 fz-12 text_blue letter_sp'>{row.desc}</div></td>
                                        <td ><div className=' fz-12  letter_sp'>{row.recv_on}</div></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Sheet>
                </Box>
            </Box>
        </Box>
    )
}
