import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const Settings = () => {
  const params = useParams()
  const [is2FAEnabled, setIs2FAEnabled] = useState(null);
  console.log(params.id)

  // Fetch the current 2FA status when the component mounts
  useEffect(() => {
    const fetch2FAStatus = async () => {
      try {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getTeachersbyid/${params.id}`); // Replace with your actual API endpoint
        console.log(res)
        setIs2FAEnabled(res.data.teachers[0].isTwoFactorAuthentocated);
        
      } catch (error) {
        console.error('Error fetching 2FA status:', error);
      }
    };
    
    fetch2FAStatus();
  }, []);

  

  const handleToggle = async () => {
    const newStatus = !is2FAEnabled;
    const body = {
      isTwoFactorAuthentocated: newStatus,
    }

    try {
      const res = await axios.put(process.env.REACT_APP_BASE_URL + `Admin/updateTofactorAuth/${params.id}`, body); // Replace with your actual API endpoint
      setIs2FAEnabled(res.data.update.isTwoFactorAuthentocated);
      console.log(res.data.update.isTwoFactorAuthentocated)

    } catch (error) {
      console.error("Error updating 2FA status:", error);
      // Optionally revert the state if the API call fails
      setIs2FAEnabled(!newStatus);
    }
  };

  return (
   <>
   <Box className='headingBox' sx={{margin:"5px 0 0 5px"}}>
        <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>2-Factor Authentication</Typography>
    </Box>
    <Box className=" ">
        <Box className='mb-20'>
        <FormControlLabel
            className="checkbox_list"
            control={
              <Checkbox
                checked={is2FAEnabled}
                onChange={handleToggle}
                name='checkbox-value'
                color='primary'
              />
            }
            label={
              <Typography component='div' className='label mb-0'>
                {is2FAEnabled ? 'Turn off 2-factor authentication' : 'Turn on 2-factor authentication'}
              </Typography>
            }
          />
        </Box>
    </Box>
   </>
  )
}

export default Settings

// ------------------------------------------------------------------------

