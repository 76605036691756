import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import MiniDrawer from '../../../../../components/drawer';
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, IconButton, FormControlLabel, Tooltip, Card, Checkbox, Link, ListItemText, OutlinedInput, Input, Breadcrumbs, TableContainer, Select, TablePagination, MenuItem, Dialog, Typography, Grid, FormControl, TextField, DialogContent, DialogActions, DialogTitle, styled, CardContent, AccordionDetails, AccordionSummary, Accordion, Divider, RadioGroup, Radio, InputAdornment, Tabs, Tab } from '@mui/material';
import { Category, Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import { AiOutlineInfoCircle } from 'react-icons/ai'; // Example icon from React Icons
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Sidebar from './../../Sidebar'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function BpRadio(props) {
	return (
		<Radio
			sx={{
				'&:hover': {
					bgcolor: 'transparent',
				},
			}}
			disableRipple
			color="default"
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			{...props}
		/>
	);
}
const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: '50%',
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.mode === 'dark'
			? '0 0 0 1px rgb(16 22 26 / 40%)'
			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
	backgroundImage:
		theme.palette.mode === 'dark'
			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
	},
}));

const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#f3714d',
	backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 16,
		height: 16,
		backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#f3714d',
	},
});

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-/addjobdetailstrack': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;
    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#f3714d',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#f3714d',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <ListAlt />,
        2: <CompareIcon />,
        3: <Dvr />,
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const steps = ['Basic Information', 'Additional Information'];

export default function Service_Steppyform() {
    const auth = JSON.parse(localStorage.getItem("userData"))
    const navigate = useNavigate()
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const [activeStep, setActiveStep] = React.useState(0);
    const params = useParams()
    const { enqueueSnackbar } = useSnackbar()
    const [selectedPlan, setSelectedPlan] = React.useState('')
    const [truebutton, setTruebutton] = React.useState(false)
    const [error, setError] = React.useState({titlemsg:'', amount: "",annualamount:'', interval: "" })
    const [categoryId, setCategoryId] = React.useState('')
    const [courseId, setCourseId] = React.useState([])
    const [userId, setUserId] = React.useState([])
    const [subcategoryId, setSubCategoryId] = React.useState('')
    const [imageError, setImageError] = React.useState('')
    const [imageError2, setImageError2] = React.useState('')
    const [imageAddError, setImageAddError] = React.useState('')
    const [state1, setState1] = React.useState([])
    const [state2, setState2] = React.useState([])
    const [statesingle, setStatesingle] = React.useState([])
    const [statesingle2, setStatesingle2] = React.useState([])
    const [state3, setState3] = React.useState([])
    const [state4, setState4] = React.useState([])
    const [statesingle3, setStatesingle3] = React.useState([])
    const [statesingle4, setStatesingle4] = React.useState([])
    const [convertedContent, setConvertedContent] = React.useState(null);
    const [description, setDescription] = React.useState('')
    const [currency, setCurrency] = React.useState('USD')
    const [annualcurrency,setAnnualCurrency] = React.useState('USD')
    const [amount, setAmount] = React.useState('')
    const [annualamount,setAnnualAmount] = React.useState('')
    const [category, setCategory] = React.useState([])
    const [interval, setInterval] = React.useState('monthly')
    const [annualinterval,setAnnualInterval] = React.useState('year')
    const [communitytype, setCommunitytype] = React.useState('')
    const [publicFanPage, setPublicFanPage] = useState(false)
    const [title, setTitle] = useState('')
    const [addFanPageFrom, setaddFanPageFrom] = useState('Teacher')
    const [sathsanga,setSathSanga] = React.useState('')
    const [onlinetrain,setOnlinetrain] = React.useState('')
    const [batches,setBathches] = React.useState('')
    const [seminars,setSeminar] = React.useState('')
    const [paymenttype,setPaymentType] = React.useState('paid')
    


    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }


    const SingleImage = (e, index) => {
        setImageError('');
        setError('');

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files);

        for (let i = 0; i < fileObj[0].length; i++) {
            const img = new Image();
            img.src = URL.createObjectURL(fileObj[0][i]);

            img.onload = function () {
                if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
                    setImageError('Image size should be less than 1 MB.');
                } else {
                    fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    filepath.push(fileObj[0][i]);

                    var data = statesingle.filter(x => x != null);
                    var data1 = statesingle2.filter(x => x != null);
                    setStatesingle(data);
                    setStatesingle2(data1);
                    setStatesingle(prevValue => prevValue.concat(fileArray));
                    setStatesingle2(prevValue => prevValue.concat(filepath));
                }
            };
        }
    };
    const SingleProfileImage = (e, index) => {
        setImageError2('');
        setError('');

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files);

        for (let i = 0; i < fileObj[0].length; i++) {
            const img = new Image();
            img.src = URL.createObjectURL(fileObj[0][i]);

            img.onload = function () {
                if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
                    setImageError2('Image size should be less than 1 MB.');
                } else {
                    fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    filepath.push(fileObj[0][i]);

                    var data = statesingle3.filter(x => x != null);
                    var data1 = statesingle4.filter(x => x != null);
                    setStatesingle3(data);
                    setStatesingle4(data1);
                    setStatesingle3(prevValue => prevValue.concat(fileArray));
                    setStatesingle4(prevValue => prevValue.concat(filepath));
                }
            };
        }
    };
    const indexDelete2 = (e, index) => {
        var filteredpreview = statesingle3.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle4.filter((val, i) => {
            return i !== index;
        });
        setStatesingle3(filteredpreview);
        setStatesingle4(filteredraw);
    }

    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }


    const multipleImgChange = (e, index) => {
        setImageAddError("");
    
        // Validate file size
        const maxSize = 1024 * 1024; // 1MB in bytes
        const files = e.target.files;
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
    
        for (let i = 0; i < files.length; i++) {
          if (files[i].size > maxSize) {
            setImageAddError("Image size should be less than 1 MB.");
            continue; 
          }
    
          fileObj.push(files[i]);
          fileArray.push(URL.createObjectURL(files[i]));
          filepath.push(files[i]);
        }
    
        var data = state1.filter((x) => x != null);
        var data1 = state2.filter((x) => x != null);
        setState1(data);
        setState2(data1);
        setState1((prevValue) => prevValue.concat(fileArray));
        setState2((prevValue) => prevValue.concat(filepath));
      };
    
      const MulImgsindexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
          return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
          return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
      };

    const validate = () => {
        if(!title){
            setError({titlemsg:'This field is required'})
            return false
        }
        if (statesingle2?.length == 0) {
            setImageError("This field is required.")
            return false
        }
        if (statesingle4?.length == 0) {
            setImageError2("This field is required.")
            return false
        }
        if(paymenttype == 'paid'){
            if (!amount) {
                setError({amount: "This field is required." })
                return false
            }
            if (!annualamount) {
                setError({annualamount: "This field is required." })
                return false
            }
        }
        return true
    }

    const step2Validation = () => {
        if (state2?.length === 0) {
            setImageAddError("This field is required.");
            return false
        }
        return true
    }

    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };

    const handleChangeStatus = async (e) => {
        setPublicFanPage(e.target.checked)
    };


   

//Fan Page functionality:
    const handleAddFanPage = (e, details) => {
        var multipleImage = state2.filter((x) => x != null);
        var valid = validate();
        var valid1 = step2Validation();
        if (valid == true && valid1 == true) {
            const formData = new FormData();
            formData.append('addFanPageFrom',addFanPageFrom )
            formData.append('createdby[]', params && params.id);
            formData.append("amountInMonth", amount);
            formData.append("amountInYear",annualamount)
            formData.append("fanspage_paymentype",paymenttype);
            // formData.append("interval", interval);
            formData.append("short_description", description);
            formData.append("currency", currency);
            formData.append("subcategory_id", subcategoryId);
            formData.append("name", title);
            formData.append("sathSangs_conducted",sathsanga)
            formData.append("online_trainings",onlinetrain)
            formData.append("pranayama_batches",batches)
            formData.append("international_yoga_seminars",seminars)
            formData.append("public", publicFanPage);
            statesingle2.forEach((item) => formData.append("newimage", item));
            statesingle4.forEach((item) => formData.append("profile", item));
            multipleImage?.forEach((item) => { formData.append("additionalimages", item) }) 
            setTruebutton(true);
            axios.post(process.env.REACT_APP_BASE_URL + `FanPage/AddYogaFansPage`, formData, { headers: headerformdata })
                .then((res) => {
                    setTruebutton(false);
                    enqueueSnackbar("Fan Page added successfully", { variant: 'success' });
                    navigate(`/teachers/details/fans/${params && params.id}`)
                    setInterval('')
                    setTitle('')
                    setAmount('')
                    setStatesingle([]);
                    setStatesingle2([]);
                    setDescription('');
                    setStatesingle3([]);
                    setStatesingle4([]);
                }).catch((err) => {
                    setTruebutton(false);
                    enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' });
                    console.log(err, "error");
                });
            
        }
    };

//Redirecting To next Step:
    const handleNext = () => {
    
        var valid = validate();
    
        if (valid === true) {
          if (activeStep === 1) {
            const validatation = validate();
            
            if (validatation === true) {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
          } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
      };

      const handleBack = () => {
        setImageAddError('')
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };
    

    return (
        // <Box className='withSubmenu'>
        <Box className={auth.role_id == 1 ? 'withSubmenu' : ''}>
             {auth.role_id == 1 ?             <MiniDrawer menuOrder={2} submenuOrder={1} />
 :   <MiniDrawer menuOrder={17} />} 
            {/* <Sidebar subtype={9} /> */}
            {auth.role_id == 1 ? <Sidebar subtype={8} /> : ''}

            <Box component="section" className="contentWraper">

                <Grid item xs={12} sm={12} md={8} lg={6}  >
                    <Box className='whiteContainer p-20'>
                        <Typography component='h6' className='formTitle mb-20'>Add Fan Page</Typography>

                        <Stepper
                            alternativeLabel
                            activeStep={activeStep}
                            connector={<ColorlibConnector />}
                        >
                            {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                {label}
                                </StepLabel>
                            </Step>
                            ))}
                        </Stepper>
                        {activeStep === 0 && (
                        <Box className="steppy_content ">
                         
                            <Grid item xs={12} sm={6} md={4} lg={3} className="mb-20">
                                <FormControl variant="standard" fullWidth>
                                    <Typography component='div' className='label'> Title <Typography component='span' className='star'>*</Typography></Typography>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        size="small"
                                        className='textfield'
                                        placeholder='Title'
                                        name="title"
                                        value={title}
                                        onChange={(e) => { setTitle(e.target.value); setError('') }}

                                    />
                                    {error.titlemsg && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.titlemsg}</p>}
                                </FormControl>
                            </Grid>

                            <Grid container columnSpacing={2} rowSpacing={2} className="mb-20">
										<Grid item xs={12} sm={6} md={6} lg={6}>
											<FormControl variant="standard" fullWidth>
												<Typography component='div' className='label'>SathSanga Conducted<Typography component='span' className='star'></Typography></Typography>
												<TextField
													id="filled-size-small"
													variant="outlined"
													size="small"
													className='textfield'
													placeholder='SathSanga Conducted'
													name="amount"
													onInput={(e) => onlyNumbers(e)}
													value={sathsanga}
													onChange={(e) => {setSathSanga(e.target.value)}} 
                                                    />
											</FormControl>
										</Grid>

										<Grid item xs={12} sm={6} md={6} lg={6}>
                                            <FormControl variant="standard" fullWidth>
												<Typography component='div' className='label'>Online Trainings<Typography component='span' className='star'></Typography></Typography>
												<TextField
													id="filled-size-small"
													variant="outlined"
													size="small"
													className='textfield'
													placeholder='Online Trainings'
													name="amount"
													onInput={(e) => onlyNumbers(e)}
													value={onlinetrain}
													onChange={(e) => {setOnlinetrain(e.target.value)}} 
                                                    />
											</FormControl>

										</Grid>
                            </Grid>

                            <Grid container columnSpacing={2} rowSpacing={2} className="mb-20">
										<Grid item xs={12} sm={6} md={6} lg={6}>
											<FormControl variant="standard" fullWidth>
												<Typography component='div' className='label'>Pranayama Batches<Typography component='span' className='star'></Typography></Typography>
												<TextField
													id="filled-size-small"
													variant="outlined"
													size="small"
													className='textfield'
													placeholder='Pranayama Batches'
													name="amount"
													onInput={(e) => onlyNumbers(e)}
													value={batches}
													onChange={(e) => {setBathches(e.target.value)}} 
                                                    />
												{/* {error.title && <p style={{ color: 'red' }} className="fz-13 m-0">{error.title}</p>} */}
											</FormControl>
										</Grid>

										<Grid item xs={12} sm={6} md={6} lg={6}>
                                            <FormControl variant="standard" fullWidth>
												<Typography component='div' className='label'>International Yoga Seminars<Typography component='span' className='star'></Typography></Typography>
												<TextField
													id="filled-size-small"
													variant="outlined"
													size="small"
													className='textfield'
													placeholder='International Yoga Seminars'
													name="amount"
													onInput={(e) => onlyNumbers(e)}
													value={seminars}
													onChange={(e) => {setSeminar(e.target.value)}} 
                                                    />
												{/* {error.title && <p style={{ color: 'red' }} className="fz-13 m-0">{error.title}</p>} */}
											</FormControl>

										</Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}  >

                                <Typography component='div' className='label mb-10'>Primary Image<Typography component='span' className='star'> * </Typography></Typography>
                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                    {statesingle.length < 1 &&
                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                            <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                            {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}

                            <Grid item xs={12} sm={12} md={12}  >
                                {statesingle.length > 0 &&
                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                        {statesingle.length > 0 ? statesingle.map((x, i) => (
                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                <Box className='uploaded_img' >
                                                    <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                    <img src={x} alt='' height={155} width={195} />
                                                </Box>
                                            </Grid>
                                        )) : ""}
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 500px * 600px for better quality picture.</Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}  >

                                <Typography component='div' className='label mb-10'>Secondary Image<Typography component='span' className='star'> * </Typography></Typography>
                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                    {statesingle3.length < 1 &&
                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                            <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleProfileImage} hidden type="file" />
                                        </Button>
                                    }
                                </Box>

                            </Grid>
                            {imageError2 && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError2}</p>}

                            <Grid item xs={12} sm={12} md={12}  >
                                {statesingle3.length > 0 &&
                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                        {statesingle3.length > 0 ? statesingle3.map((x, i) => (
                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                <Box className='uploaded_img' >
                                                    <Button className='close_icon' onClick={(e) => indexDelete2(e, i)}><Close /></Button>
                                                    <img src={x} alt='' height={155} width={195} />
                                                </Box>
                                            </Grid>
                                        )) : ""}
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
                            </Grid>

                            <Box className='mb-20'>
                                <Typography component='div' variant='body1' className='fz-14 semibold mr-10'>Public Fan Page    </Typography>
                                <FormControlLabel className='m-0' control={<Switch size="small" onChange={(e) => handleChangeStatus(e)} checked={publicFanPage} />} />
                            </Box>


                            <Box className="mb-20 ">
                                <Typography component='div' className='label'>Choose Payment Mode</Typography>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-customized-radios"
                                    name="payment_type"
                                    onChange={(e)=>setPaymentType(e.target.value)}
                                    value={paymenttype}
                                >
                                    <FormControlLabel
                                        value="paid"
                                        className='radio-btns'
                                        control={<BpRadio />}
                                        label={<Typography className='fz-14'>Annual/Monthly</Typography>}
                                    />
                                    <FormControlLabel
                                        value="free"
                                        className='radio-btns'
                                        control={<BpRadio />}
                                        label={<Typography className='fz-14'>Free</Typography>}
                                    />
                                </RadioGroup>
                            </Box>

                                {paymenttype == 'paid' &&
                                    <Grid container columnSpacing={2} rowSpacing={2} className="mb-20">
										<Grid item xs={12} sm={6} md={4} lg={4}>
											<FormControl variant="standard" fullWidth>
												<Typography component='div' className='label'>Monthly Price<Typography component='span' className='star'> *</Typography></Typography>
												<TextField
													id="filled-size-small"
													variant="outlined"
													size="small"
													className='textfield'
													placeholder='Amount'
                                                    name="amount"
                                                    onInput={(e) => onlyNumbers(e)}
                                                    value={amount}
                                                    onChange={(e) => { setAmount(e.target.value); setError(''); }} />
												{error.amount && <p style={{ color: 'red' }} className="fz-13 m-0">{error.amount}</p>}
											</FormControl>
							            </Grid>

							            {/* <Grid item xs={12} sm={6} md={4} lg={4}>
											<FormControl fullWidth>
												<Typography component='div' className='label'>Currency<Typography component='span' className='star'></Typography></Typography>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={currency}
													placeholder="Select Currency"
													className="select"
													onChange={(e) => { setCurrency(e.target.value); setError(''); }}
												>
													<MenuItem disabled>Select Currency</MenuItem>
													<MenuItem value={"USD"}>USD</MenuItem>
												</Select>
                                               
											</FormControl>
                                        </Grid> */}

							            {/* <Grid item xs={12} sm={6} md={4} lg={4}>
											<FormControl fullWidth>
												<Typography component='div' className='label'>Interval<Typography component='span' className='star'></Typography></Typography>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={interval}
													placeholder="Select Interval"
													className="select"
													onChange={(e) => { setInterval(e.target.value); setError(''); }}
												>
													<MenuItem disabled value={'sel'}>Select Interval</MenuItem>
													<MenuItem value={"month"}>Monthly</MenuItem>
												</Select>
													
											</FormControl>
										</Grid> */}

                                        
						            </Grid> }

                                    {paymenttype == 'paid' &&
                                    <Grid container columnSpacing={2} rowSpacing={2} className="mb-20">
										<Grid item xs={12} sm={6} md={4} lg={4}>
											<FormControl variant="standard" fullWidth>
												<Typography component='div' className='label'>Annual Price<Typography component='span' className='star'> *</Typography></Typography>
												<TextField
													id="filled-size-small"
													variant="outlined"
													size="small"
													className='textfield'
													placeholder='Amount'
                                                    name="amount"
                                                    onInput={(e) => onlyNumbers(e)}
                                                    value={annualamount}
                                                    onChange={(e) => { setAnnualAmount(e.target.value); setError(''); }} />
												{error.annualamount && <p style={{ color: 'red' }} className="fz-13 m-0">{error.annualamount}</p>}
											</FormControl>
							            </Grid>

							            {/* <Grid item xs={12} sm={6} md={4} lg={4}>
											<FormControl fullWidth>
												<Typography component='div' className='label'>Currency<Typography component='span' className='star'></Typography></Typography>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={currency}
													placeholder="Select Currency"
													className="select"
													onChange={(e) => { setCurrency(e.target.value); setError(''); }}
												>
													<MenuItem disabled>Select Currency</MenuItem>
													<MenuItem value={"USD"}>USD</MenuItem>
												</Select>
                                               
											</FormControl>
                                        </Grid> */}

							            {/* <Grid item xs={12} sm={6} md={4} lg={4}>
											<FormControl fullWidth>
												<Typography component='div' className='label'>Interval<Typography component='span' className='star'></Typography></Typography>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={interval}
													placeholder="Select Interval"
													className="select"
													onChange={(e) => { setInterval(e.target.value); setError(''); }}
												>
													<MenuItem disabled value={'sel'}>Select Interval</MenuItem>
													
													<MenuItem value={"year"}>Yearly</MenuItem>
													
												</Select>
                                                 
											</FormControl>
										</Grid> */}

                                        
						            </Grid> }
                                
                            
                        </Box>)}
                        {activeStep === 0 && (
                            <Box
                            className="heading-btns mt-40"
                            style={{ textAlign: "end" }}
                          >
                            <Button
                              onClick={handleNext}
                              variant="contained"
                              className="theme-btn  "
                            >
                              Next
                            </Button>
                          </Box>
                        
                        )}



         {activeStep === 1 && (
            <Box className='steppy_content'>

                <Grid item xs={12} sm={12} md={12}>
                      <Box className="mb-10 ">
                        <Typography component="div" className="label">
                          Additional Images
                          <Typography component="span" className="star">
                            *
                          </Typography>
                        </Typography>

                        {state1.length < 1 && (
                          <Button
                            className="multiple_upload_btn"
                            variant="contained"
                            component="label"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <FileUploadOutlinedIcon className="fz-18 mr-5" />{" "}
                              Upload
                            </Box>
                            <input
                              accept={"image/png, image/jpg,image/jpeg"}
                              onChange={multipleImgChange}
                              hidden
                              multiple
                              type="file"
                            />
                          </Button>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      {state1.length > 0 && (
                        <Grid
                          container
                          columnSpacing={1}
                          rowSpacing={1}
                          className="multiple_upload_container mb-10"
                        >
                          {state1.length > 0
                            ? state1.map((x, i) => (
                                <Grid item xs={6} sm={4} md={3} lg={3}>
                                  <Box className="uploaded_img">
                                    <Button
                                      className="close_icon"
                                      onClick={(e) => MulImgsindexDelete(e, i)}
                                    >
                                      <Close />
                                    </Button>
                                    <img
                                      src={x}
                                      alt=""
                                      height={155}
                                      width={195}
                                    />
                                  </Box>
                                </Grid>
                              ))
                            : ""}
                          <Grid item xs={6} sm={4} md={3} lg={3}>
                            <label
                              htmlFor="contained-button-file1"
                              className="w-full"
                            >
                              <Box className="multiple_upload_btn">
                                <Input
                                  inputProps={{
                                    accept: "image/png, image/jpg,image/jpeg",
                                  }}
                                  onChange={multipleImgChange}
                                  style={{ display: "none" }}
                                  id="contained-button-file1"
                                  type="file"
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <FileUploadOutlinedIcon />
                                </Box>
                              </Box>
                            </label>
                          </Grid>
                        </Grid>
                      )}
                      <Typography
                        className=" fz-12 mt-10 mb-10"
                        component="p"
                        variant="p"
                      >
                        <strong>Note:</strong> Image size should be more than
                        280px * 280px for better quality picture.
                      </Typography>
                      {imageAddError && (
                        <p style={{ color: "red" }} className="fz-13 m-0">
                          {imageAddError}
                        </p>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} style={{marginTop:20}} >
                        <Box className="mb-10">
                            <Typography component='div' className='label'>Short Description</Typography>

                            <FormControl variant="standard" fullWidth>
                                <textarea
                                placeholder='Short Description '
                                className='textarea'
                                defaultValue={description}
                                onChange={(e) => setDescription(e.target.value)}
                                rows={4}
                                cols={40}
                                style={{ resize: 'none' }} />
                            </FormControl>
                        </Box>
                    </Grid>


        {activeStep === 1 && (
            <Box className="heading-btns mt-20" style={{ textAlign: "end" }}>
              <Button
                onClick={handleBack}
                variant="contained"
                className="theme-btn  mr-10"
              >
                Back
              </Button>
              {truebutton === false ? (
                <Button
                  onClick={(e)=>handleAddFanPage(e, 'add')}
                  variant="contained"
                  className="theme-btn"
                >
                  Submit
                </Button>
              ) : (
                <Button variant="contained" className="theme-btn  mr-10">
                  Please wait...
                </Button>
              )}
            </Box>
          )}
                        </Box>
                    )}
                        
                    </Box >
                </Grid>

            </Box >
        </Box >
    )
}