import { Box, Button, Divider, Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Sheet } from '@mui/joy'
import Table from '@mui/joy/Table';
import { Info } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router-dom';




export default function BookReview() {
//UseState's:
    const [bookdashboard,setBookdashboard] = React.useState([])
    const [totalbooks,setTotalBooks] = React.useState(0)
    const params = useParams()



//Hook's state:
    React.useEffect(()=>{
        BookReviewDashboard()
    },[])


    const BookReviewDashboard = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `BookReview/GetbookReviewsbyCreatorId/${params.id}?size=${10}&page=${10}&search=${""}&category_id=${"all"}`)
        .then((res)=>{
            setBookdashboard(res.data.getBookReview);
            setTotalBooks(res.data.totalcount)
        })
        .catch((error)=>{
            console.log(error,"error in BlogDashboard");
        })
    }



    return (
        <Box className="individual_table">
            <Box className="p-10 flex-between flex-center">
                <Box className="flex-center">
                    <Typography component='h6' variant='h6' className='semibold letter_sp fz-16 mr-10'>Book Reviews</Typography>
                    <Tooltip title="watch video" className='flex-center text-right'>
                        <Info className='fz-18' />
                    </Tooltip>
                </Box>
                <Typography component='div' variant='body' className='countBox fz-24'>{totalbooks}</Typography>
            </Box>
            <Divider />
            <Box className="p-10">
                {/* <Box className="d-flex justify-content-right">
                    <Tooltip title="watch video" className='flex-center text-right'>
                        <Button className='theme_btn btn-xs'>Add or Edit Book Review</Button>
                    </Tooltip>
                </Box> */}
                <Box className="table_content">
                    <Sheet sx={{ height: 200, overflow: 'auto' }}>
                        <Table
                            aria-label="table with sticky header"
                            stickyHeader
                            stickyFooter
                            stripe="odd"
                            hoverRow
                        >
                            <thead>
                                <tr>
                                    <th className='minw100'>Book Name</th>
                                    <th className='minw300'>Description</th>
                                    <th className='text-center minw100'>Added on</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bookdashboard && bookdashboard?.length > 0 ? 
                                (bookdashboard && bookdashboard?.map((item,i) => (
                                    <tr key={i}>
                                        <td className='semibold fz-12' >{item.Book_title}</td>
                                        <td  ><Box className='ellipse2 fz-12 text_blue letter_sp'>{item.Book_shortdescription ? item.Book_shortdescription : '-'}</Box></td>
                                        <td ><div className='semibold fz-12 text-center'>{moment(item.createdAt).format('MMM Do YY')}</div></td>
                                    </tr>
                                ))) : (
                                    <p>No data found</p>
                                )}
                            </tbody>
                        </Table>
                    </Sheet>
                </Box>
            </Box>
        </Box>
    )
}
